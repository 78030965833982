import axios from "axios";
import router from "@/router/index.js";

// default timeout
axios.defaults.timeout = 5000;

// relate path
axios.defaults.baseURL = "https://firstpayer.com/api/";

//http request filter
axios.interceptors.request.use(
  (config) => {
    // get token from local
    const token = localStorage.getItem("cc_token");
    // set param fmt
    if (!config.headers["Content-Type"]) {
      config.headers = {
        "Content-Type": "application/json",
        // application/x-www-form-urlencoded
      };
    }
    // add token to headers
    if (token) {
      config.headers.token = token;
    }
    
    if (config.method === "get") {
      config.params = config.params || {};
      // let json = JSON.parse(JSON.stringify(config.params));
    } else {
      config.data = config.data || {};
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//http response filter
axios.interceptors.response.use(
  (response) => {
    //deal response code
    if (response.data.code === 501) {
      router.push({
        name: "login", //page jump 
        query: {
          retUrl: window.location.href.split("#")[1] || "",
          is_new_user_url: 1,
        },
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios