<template>
	<div class="contact-box">
		<div class="register-box">
			<div class="item left">
				<div class="left-content">
					<h2 class="h2_t">Start your free integration now</h2>
					<p class="h2_t_sub">
						Four steps to quick access and free integration
					</p>
					<div class="block">
						<el-timeline>
							<el-timeline-item
								v-for="(activity, index) in activities"
								:key="index"
								:color="activity.color"
								:size="activity.size"
							>
								{{ activity.content }}
							</el-timeline-item>
						</el-timeline>
					</div>
				</div>
			</div>
			<div class="item right">
				<div class="form-wrapper">
					<el-form
						:model="ruleForm"
						:rules="rules"
						ref="ruleForm"
						label-width="100px"
						class="demo-ruleForm"
					>
						<el-form-item label="Name" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item prop="email" label="Email">
							<el-input v-model="ruleForm.email"></el-input>
						</el-form-item>

						<el-form-item prop="phone" label="Phone*">
							<el-input v-model="ruleForm.phone"></el-input>
						</el-form-item>

						<el-form-item label="Company Name*" prop="Company">
							<el-input v-model="ruleForm.company"></el-input>
						</el-form-item>
						<el-form-item label="Message*" prop="desc">
							<el-input
								type="textarea"
								v-model="ruleForm.message"
							></el-input>
						</el-form-item>
						<el-form-item>
							<el-button>User Feedback</el-button>
							<el-button
								type="primary"
								@click="submitForm('ruleForm')"
								>Send</el-button
							>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getFeedbackApi } from '@/api/feedback'
export default {
	name: 'ContactUs',
	data() {
		var checkPhone = (rule, value, callback) => {
            var reg_tel = /^[\d\\+\s]+$/i; 
			if (!value) {
				return callback(new Error('Please input your phone'))
			}
			setTimeout(() => {
                if(!reg_tel.test(value)){
                    callback(new Error('Please input valid phone'))
                }
			}, 1000)
		}
		return {
			activities: [
				{
					content: 'KYC review',
					color: '#00D7AE',
					size: 'large',
				},
				{
					content: 'Sign contract',
					color: '#00D7AE',
					size: 'large',
				},
				{
					content: 'Integration',
					color: '#00D7AE',
					size: 'large',
				},
				{
					content: 'Product release',
					color: '#00D7AE',
					size: 'large',
				},
			],
			ruleForm: {
				name: '',
				email: '',
				phone: '',
				company: '',
				message: '',
			},
			rules: {
				name: [
					{
						required: true,
						message: 'please enter name',
						trigger: 'blur',
					},
					{
						min: 3,
						max: 50,
						message: 'name is too short or too lang',
						trigger: 'blur',
					},
				],
				email: [
					{
						required: true,
						message: 'please input email',
						trigger: 'blur',
					},
					{
						type: 'email',
						message: 'please input valid email',
						trigger: ['blur', 'change'],
					},
				],
				phone: [{ validator: checkPhone, trigger: 'blur' }],
			},
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) {
					// this.$message.error('invalid request, please try again!!!!')
					return false
				} else {
					let params = {
						name: this.ruleForm.name,
						email: this.ruleForm.email,
						phone: this.ruleForm.phone,
						company: this.ruleForm.company,
						msg: this.ruleForm.message,
					}
					getFeedbackApi(params).then((res) => {
						let msg = ''
						if (!res || !res.data) {
							msg = 'maybe network error'
							this.$message.error(
								'there is something wrong: [' +
									msg +
									'], try again'
							)
							return false
						}

						if (res.data.errcode != 0) {
							msg = res.data.errcode + ': ' + res.data.errmsg
							this.$message.error(
								'there is something wrong: [' +
									msg +
									'], try again'
							)
							return false
						}
						this.$message.success(
							'Thanks for Believing in US, We will contact you as soon as we can'
						)
						return false
					})
				}
			})
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../static/css/contactUs.css';
</style>
