<template>
  <div class="tc-box">
    <div class="text1">
      <h1>Terms and Conditions</h1>
      <p>
        Your use of Funshine Tech Private Limited (henceforth FirstPayer) Online
        Service and Products (referred to as the offering in this document as
        the “Offering” and excluding any offering provided to you by FirstPayer
        under a separate written agreement) is subject to these terms, without
        prejudice to any additional terms which may be part of an agreement
        specific to the service you wish to avail of. Your agreement with
        FirstPayer will always include at a minimum, the terms and conditions
        set out in this document.
      </p>
      <p>
        Accepting the Terms: FirstPayer shall interact with its Users
        (“Merchants” and replaceable with “You” using the Platform) through our
        website. By clicking a button or checkbox on a website, you accept and
        agree to the terms, where this option is made available to you by
        FirstPayer in the User interface for any offering.
      </p>
      <p>
        At FirstPayer, we value legal activities, compliances and the word of
        the Law. We take our legal obligations seriously and hence, mandate that
        in order to use the offering, you must first agree to the terms. You may
        not use the offering if you do not accept the terms. Your use of our
        Offering in any manner is a deemed acceptance of each of the terms
        mentioned herein and other Policies or terms mentioned. If you require
        any assistance using our services please contact us
        on&nbsp;support@firstpayer.com&nbsp;or contact our grievance officer
        directly.
      </p>

      <p>
        That these terms and conditions shall be read along with and in addition
        to any, all and each terms & conditions and policies which shall come
        into being from time to time and may or may not be published on our
        website. All applicable terms and conditions, policies and rules shall
        be read in conjunction with our Privacy policies, other applicable
        policies, as well as laws and a User shall use our Offerings only and
        only upon absolute and complete acceptance of our terms, conditions and
        policies with no exceptions whatsoever. In the event that you do not
        agree with our policies, terms and conditions in any part, or absolution
        thereof, you must not use our Offerings further and shall stop using
        them immediately.
      </p>

      <p>
        In the event that you wish to stop using our Offerings in the middle of
        your usage of the same, you must intimate us of the same immediately so
        We can stop your usage of the Offerings and ensure that the terms are
        complied herewith.
      </p>

      <p>
        What does this document contain?<br />
        1.Definitions<br />
        2.Eligibility<br />
        3.Registration<br />
        4.Provision of the offering by FirstPayer<br />
        5.Taxes<br />
        6.Relationship between User and FirstPayer<br />
        7.User of Services<br />
        8.Sharing of information<br />
        9.User Obligations<br />
        10.Your passwords and account security<br />
        11. Misrepresentation to FirstPayer or Customers<br />
        12.Content in the offering<br />
      </p>

      <h3>Definitions</h3>
      <p>
        For The Purpose Of These Terms, we are only defining certain terms that
        we refer to specifically, otherwise, other Terms and words shall have
        the same connotation as they do in common parlance, industry
        communications, etc.
      </p>
      <p>
        The Following Words And Phrases Shall Have The Meaning Assigned To Them
        Under This Article or as are used in the industry or legal meaning that
        each word might have.
      </p>

      <h3>Services</h3>
      <p>
        Means The Service Provided By FirstPayer To Users, Whereby A User Can<br />
        Receive Payments From Their Customers Or Other Payers;<br />
        Make Payment To Suppliers, Beneficiaries Or Other Payees<br />

        IMPS/NEFT/RTGS;<br />
        Credit/ Debit Card; <br />
        Net Banking; <br />
        Any Other Mode Of Payment Through Banks, That May Be Accepted By
        FirstPayer From Time To Time In These Terms.
      </p>

      <h3>User</h3>
      <p>
        Shall Mean Any Person or Entity (“Merchants” using the FirstPayer
        Offering) Who Is Boarded On The FirstPayer Platform For Using Their
        service or This Website And/Or Who Transacts Or Avails Of The Services
        Through This Website, To Make Or To Receive Payments To Or From Third
        Parties. The Term "User" Shall Also Cover Such Personnel/Team members Of
        Any User Who Are Authorized To Avail Of The Services On Behalf Of The
        User. The User can be both Indian as well as International entities
        provided that both have been duly incorporated for lawful purposes and
        are following lawful business practices and are not violating any laws
        in India or their own resident countries.
      </p>
      <p>
        It is important to note that all persons using the User’s account on our
        platform is deemed to be authorised or have the authority to do so,
        unless the registered User has complained for unauthorized access of
        platform or has expressed concern with respect to use of the Platform by
        an unauthorized individual/entity, etc.
      </p>

      <h3>Customer</h3>
      <p>
        Shall mean all Customers transacting on the Platform to make purchases
        for services or products that belong to the Merchants or Users. The
        Customer accepts, agrees and understands that each of these transactions
        are made with the Merchant or Users and not with FirstPayer. FirstPayer
        is only a Payment Aggregator as per the definitions of the RBI
        guidelines. In the event that the Customers have any claims against any
        products purchased or services paid for, the Customers shall make such
        claims against the Merchants only and FirstPayer shall offer and extend
        all possible assistance that the Customer requires to deal with such
        complaints. The Customer shall also include or refer to or be
        alternatively replaced with Payer or Payee, depending on the type of
        transaction that is taking place on the User’s website/portal.
      </p>

      <h3>Eligibility</h3>
      <p>
        The Website And Services Are Intended Solely For Persons Who Can Form
        Legally Binding Contracts Under Indian Law And Who Are 18 Years Of Age
        Or Older or by Duly incorporated or authorised entities and their
        authorised individuals/representatives.
      </p>

      <p>
        A User Represents And Warrants That He Has Full Power And Authority For
        Using The Services And Is In Strict Compliance Of All Laws.
      </p>

      <h3>Registration</h3>
      <p>
        In Order To Avail Our Offerings, You Must Be Registered With FirstPayer.
        In order to use our Offerings, You must Provide FirstPayer With All Such
        Documents as may be required by FirstPayer and is sought by you in a due
        manner. In the event that you are unable to provide us with requisite
        documents and details, you must not register for our Offerings.
      </p>

      <p>
        The User shall ensure that all such documents shall be authentic,
        reliable. Updated and relevant. The User is strictly prohibited to use
        our Services for the Websites/Business not registered with FirstPayer
        and the Services Providers. The User shall keep FirstPayer fully
        indemnified in the event this Clause is breached.
      </p>

      <p>
        The User shall also ensure that all details provided during or for
        registration shall be true and correct and shall not make any
        misrepresentations or state wrong facts, etc. for such registration. By
        registering with FirstPayer, You agree to provide true, accurate,
        current and complete information failing which FirstPayer may at any
        time reject User’s Registration And Terminate their Right To Use Or
        Access The Website And/Or Offerings. When User Registers With
        FirstPayer, A User Account Will Be Created, That Is Associated With
        User’s User Name, Password And Other Identifying Information. User Shall
        Be Responsible For Anything That Happens Through their User Account.
      </p>

      <h3>Provision of the Offering by FirstPayer</h3>
      <p>
        The Offering is an online and digitally enabled payment service that
        helps you accept and process online payments made by various modes,
        including credit cards, debit cards and other card payments. It is
        important for you to note, understand and remember that FirstPayer is
        not a bank or a Non Banking Financial institution, and does not
        represent or warrant that it seeks to or is capable of providing
        services for banking and allied activities in any manner whatsoever. You
        need to have an existing account with an institution capable of
        providing banking services in order to access the offering. If you wish
        to understand in further detail, about what FirstPayer does and its
        Offerings, we recommend that you contact us
        on&nbsp;support@firstpayer.com.
      </p>
      <p>
        FirstPayer provides APIs and other softwares/technology support for use
        by its Users, and will attempt to continuously innovate in order to
        provide the best possible offering for its Users. You acknowledge and
        agree that the form and nature of the offering which FirstPayer provides
        may change from time to time and that FirstPayer has absolute discretion
        to make any changes it may deem fit, with or without notice or
        intimation. In the event that there are any technological changes or
        changes in features, details, functionalities, etc of the Offerings
        FirstPayer may make it mandatory for the continuing User to adopt new
        software procedures, install new software or update the same, failing
        which the User might not be able to use the Offering.
      </p>

      <p>
        You acknowledge and agree that FirstPayer may stop (permanently or
        temporarily) providing the Offering (or any features within the
        offering) to you or to any User generally at FirstPayer's sole
        discretion, without incurring any liabilities for such discontinuation.
      </p>

      <p>
        You acknowledge and agree that if FirstPayer disables access to your
        account or login route, you may be prevented from accessing the
        offering, your account details or any files or other content which is
        contained in your account.
      </p>

      <p>
        That you are to Use FirstPayer and its Offerings on an as is basis and
        any changes that you make or attempt to make shall be in violation of
        all terms, conditions, policies that you have agreed to and accepted.
        You acknowledge and agree that while FirstPayer may not currently limit
        your use of the offering in any way, it may do so if that use hinders
        the ability of FirstPayer to carry on its operations or the ability of
        other customers to use the offering.
      </p>

      <p>
        FirstPayer shall not be responsible for any loss or damage caused by a
        modification of the features, limitation of use of the offering or the
        discontinuation altogether thereof or any change in laws, guidelines,
        etc.
      </p>

      <h3>Taxes</h3>
      <p>
        FirstPayer shall not assume any responsibility to determine what, if
        any, taxes apply to the sale of User’s goods and services and/or the
        payments the User receives in connection with User’s use of the
        FirstPayer Service (“Taxes”). It is solely User’s responsibility to
        assess, collect, report, or remit the correct tax to the proper tax
        authority. FirstPayer is not obligated to, nor will FirstPayer determine
        whether Taxes apply, or calculate, collect, report, or remit any Taxes
        to any tax authority arising from any transaction.
      </p>

      <h3>Relationship between User and FirstPayer</h3>
      <p>
        That User is an independent 3rd Party entity and accepts and agrees that
        it is, in no way, associated with FirstPayer and is merely being on
        boarded by FirstPayer as a User of its offerings.
      </p>
      <p>
        Users are owned and controlled by individuals or entities as has been
        mentioned in their KYC details.
      </p>

      <h3>Use of Offerings/Services</h3>

      <p>
        The Offerings/Services may be used by the User for receiving payments
        from third Parties. To Enable The User To Use The Offerings/Services,
        The User shall irrevocably and unconditionally accept the sole
        responsibility For Use, Confidentiality And Protection Of all
        Information Entered or provided.
      </p>

      <p>
        Owing to the kind of Services FirstPayer provides, We have no obligation
        to verify the authenticity of the transaction other than by means of
        verification of The User’s Basic KYC Information such as name, email id,
        mobile number and others may be applicable. The User Shall At All Times
        Take All Appropriate Steps, Including Those As Mentioned Herein, To
        Maintain The Security And Confidentiality Of The Information.
      </p>
      <p>
        FirstPayer Shall Not Be Liable For Any Mistake Or Misuse Of The Services
        By Either The User Or By Any Person Authorized By The User, Or By Any
        Person Gaining Access To The Services Through The User. The User Agrees
        That FirstPayer Accepts All Instructions Originating From their Account,
        Reaching their Account, Leaving their account, In Good Faith And In A
        Manner Consistent With Commercially Reasonable Security Standards and
        considers the same to be made by the User themselves, with all due
        authorisations. User Shall Indemnify And Hold FirstPayer Harmless For
        Direct Or Indirect Losses Sustained As A Result Of The Disclosure Of
        Sensitive Information By Or Through User or for any other reason
        whatsoever.
      </p>

      <p>
        Since we are only a Payment Aggregator, any confirmation regarding the
        transactions performed using valid login credentials shall be conclusive
        evidence of a transaction being effected since that is the only
        information we receive and the same is shared with us by the Merchant’s
        bank. In the event that the payment is in respect of a purchase of
        products by the Customer/Payee, FirstPayer shall not be required to
        ensure that the Purchased Product or Services have been duly
        delivered/received/etc. In the event that the Customer does choose to
        Complain about a transaction, the same should be communicated to
        FirstPayer at a maximum of 10 (ten) days from the date of the
        transaction. If there is an undue delay beyond 10 days, FirstPayer might
        not be able to duly respond to the complaint in any manner whatsoever.
      </p>
      <p>
        In order to access certain Offerings, you may be required to provide
        information about yourself as part of the registration process for the
        service, or for your continued use of the offering. You agree that any
        registration information you give to FirstPayer will always be accurate,
        correct and up to date. You are solely responsible, and FirstPayer is
        not in any way liable for the information and data collected in order to
        process and authenticate a transaction performed. You shall receive
        appropriate communication for confirmation of transaction on the
        Platform through notifications. All other communication liabilities
        shall be on the banking institution or other such Service Providers and
        not on FirstPayer.
      </p>

      <h3>Sharing of information</h3>

      <p>
        FirstPayer can and will share any information with the Government or law
        enforcement agencies (if officially requested or required by order,
        notification, statute or court order) with or without the consent of the
        concerned Party, as per requirements or order and with anyone else, with
        the consent of the concerned Party.
      </p>
      <p>
        In order to comply with our Policies and with applicable laws as well,
        You agree not to access (or attempt to access) the Offering by any means
        other than through the interface that is provided by FirstPayer, unless
        you have been specifically and expressly allowed to do so in a separate
        agreement with FirstPayer.
      </p>
      <p>
        You agree that you will not engage in any activity that interferes with
        or disrupts the Offering (or the servers and networks which are
        connected to the offering), or the Platform in any manner whatsoever.
      </p>
      <p>
        Unless you have been specifically permitted to do so in a separate
        agreement with FirstPayer, you agree that you will not reproduce,
        duplicate, copy, sell, trade or resell the offering for any purpose.
      </p>
      <p>
        You agree that you are solely responsible for (and that FirstPayer has
        no responsibility to you or to any third party for) any breach of your
        obligations under the Terms and for the consequences (including any loss
        or damage which FirstPayer may suffer) of any such breach and shall be
        held accountable for the same in any situation where you are found to be
        violating our terms, policies and conditions or violating any other
        Parties’ rights by your activities.
      </p>

      <h3>User Obligations</h3>
      <p>
        Each User Shall Be Responsible To:<br />
        (I) Furnish Correct And Accurate Information Of The Payer/ Payee As May
        Be Required, On An Independent Basis;<br />
        (II) Ensure That All Licenses And Registrations Required Are In Full
        Force And Effect To Enable Them To Carry On The Business Of Sale/
        Purchase Of Products.<br />
        (III) User Shall Not (I) Carry Out Any Activity, Which Is Banned,
        Illegal Or Immoral, (II) Use The Services In Any Manner Or In
        Furtherance Of Any Activity, Which Constitutes A Violation Of Any Law Or
        Regulation Or Which May Cause FirstPayer To Be Subject To Investigation,
        Prosecution Or Legal Action. FirstPayer is only onboarding the User and
        letting them use their offerings on the representation that has been
        made to FirstPayer. Any misrepresentation or mismatch in such
        information shall lead to immediate termination of this Agreement.<br />
        (IV) User shall never misrepresent itself as being associated with
        FirstPayer in any manner beyond what the actual/true association
        comprises of and shall in no manner, ever violate the terms or
        conditions of the Policies, etc.<br />
      </p>
      <p>
        User undertakes and assures to FirstPayer that Payments shall not be
        made/ received in respect of any Products or Services which have been
        banned under any statute, law, rule or regulation in the territory of
        India or particularly any state where in either Payer or Merchant is
        located or being accessed from, etc. or any such related, cognate or
        associated Products or Services which are unlawful, illegal, violative,
        fraudulent, inappropriate, offensive, contraband, etc. The User accepts,
        acknowledges and undertakes to only operate for lawful Products and
        Services and in an absolute and lawful manner.
      </p>

      <p>
        User shall not sell, provide, exchange or otherwise disclose to third
        Parties or Use themselves, other than for purpose of Completing a
        transaction, or as specifically required by an applicable law, any
        personal information about any third party, including the account or
        other financial details, mobile number, email address, or other such
        personal information, without obtaining prior specific written or
        express consent of such Third Party.
      </p>
      <p>
        User shall take all precautions as may be feasible or as may be directed
        by FirstPayer to ensure that there is no breach of security and that the
        integrity of the link between their Platform, the website and the
        Payment mechanism is maintained at all times.
      </p>
      <p>
        In the event of any loss being caused as a result of the
        link/network/connection being breached or as a consequence of the
        link/network/connection being improper or being in violation of the
        provisions of this clause, the loss shall be to the account of the User
        and the User shall indemnify and keep indemnified FirstPayer from any
        loss as may be caused in this regard. That the Account created on
        FirstPayer’s platform is a property of the User, so to say, and the User
        accepts and understands all liabilities and obligations of maintaining
        such account and shall ensure that the Account is kept secure & safe as
        well as shall ensure that no unlawful or illegal activities are carried
        out using the account.
      </p>
      <p>
        User shall bear and be responsible for the payment of all relevant
        Charges including but not limited to Taxes, Surcharge, Fee, Challans,
        Levies (including but not limited to any applicable withholding Taxes)
        as payable may be due.
      </p>
      <p>
        A User shall Use the information regarding a Customer (including Name,
        Address, E-Mail Address, Telephone Numbers And Other Data) conveyed to
        the User, duly during the Usage of the Services of the User, by the
        Customer, only for the purpose of completing the transaction for which
        it was furnished or provided, and not to sell or otherwise furnish such
        information to others unless the User has an independent source of such
        information or obtains the express consent of such Customer. Any
        violations herewith shall be the sole liability of the User.
      </p>
      <p>
        User shall not interfere with or damage the website or Platform
        including, without limitation, through viruses, cancel bots, trojan
        horses, harmful code, flood pings, denial-of-service attacks, backdoors,
        packet or IP spoofing, forged routing or E-mail address information or
        similar methods or technology.
      </p>
      <p>
        In the event that there is a violation of any of the above, or any other
        violation of any other manner. Occurs on the Platform or otherwise,
        FirstPayer reserves the absolute right to investigate and prosecute the
        same, to the fullest extent of the Law.
      </p>
      <p>
        The User shall be fully liable for: (A) All/Any unauthorised use of
        their account; And/Or (B) All/Any authorised transactions their Account.
      </p>

      <h3>Your passwords and account security</h3>
      <p>
        As mentioned earlier, You agree and understand that You are responsible
        to FirstPayer and to third parties for maintaining the confidentiality
        of passwords and other details, associated with any account you use to
        access the Offering. You are fully responsible for the security of data
        in your possession.
      </p>
      <p>
        You agree to comply with all applicable laws and rules in connection
        with your collection, security and dissemination of any personal,
        financial, card, or transaction information (defined as “Data”).
      </p>
      <p>
        You agree that at all times, you shall be compliant with all applicable
        laws, including without limitation, the Payment Card Industry Data
        Security Standards (PCI-DSS) and the Payment Application Data Security
        Standards (PA-DSS), as applicable. You also agree that you will use only
        PCI compliant service providers in connection with the storage or
        transmission of Card Data defined as a cardholder’s account number,
        expiration date, and CVV2. You must not store CVV2 data at any time.
      </p>
      <p>
        Information on the PCI DSS can be found on the PCI Council’s
        website&nbsp;https://www.pcisecuritystandards.org&nbsp;It is your
        responsibility to comply with these standards, as applicable. You will
        be solely responsible for all activities that occur under your account.
        You shall not:
      </p>
      <p>
        submit any card transaction for processing that does not arise from your
        sale of goods or service to a buyer customer,<br />
        act as a payment intermediary or aggregator or otherwise resell
        FirstPayer’s services on behalf of any third party,<br />
        send what you believe to be potentially fraudulent authorizations or
        fraudulent card transaction,<br />
        or use FirstPayer Service in a manner that Visa, MasterCard or any other
        payment provider reasonably believes to be an abuse of the Card Network
        or a violation of Card Network rules.<br />
      </p>
      <p>
        If you become aware of any unauthorized use of your password or of your
        account, you agree to notify the concerned official at FirstPayer
        immediately. You accept and understand that any damages caused due to
        such unauthorised use is not the liability of FirstPayer.
      </p>

      <h3>Fees</h3>
      <p>
        FirstPayer shall deduct its Transaction fees plus any Taxes per
        successful Transaction, as applicable and make payment of the balance of
        the transaction amount to the User’s account, as designated. All other
        Taxes, duties, charges etc. shall be borne and paid by the User, unless
        otherwise agreed between the Parties in a due and express manner or
        defined as per applicable laws.
      </p>
      <p>
        FirstPayer reserves the right to alter/modify/change the
        discount/commission rate as per its discretion, in accordance to
        applicable laws. FirstPayer also reserves the Right to forthwith revise
        the transaction fee payable in the event of any revision in the rates
        charged by the Banking institutions or Card associations or as per any
        applicable guidelines issued by the RBI, from time to time.
      </p>
      <p>
        It is hereby agreed and acknowledged by the Parties that the transaction
        fees charged by FirstPayer with respect to a transaction that has been
        confirmed on the Platform, shall not be returned or repaid by FirstPayer
        to the User or any other person irrespective of the transaction being
        allegedly rejected, charged back, refunded or disputed.
      </p>

      <h3>Misrepresentation</h3>
      <p>
        You shall, not enter into transactions, at any point whatsoever, to sell
        Services or Products that you have not represented/specified/listed to
        FirstPayer during your KYC procedure. In addition to this, as soon as
        You realise that there is a change in business or addition to scope of
        Your business etc. You shall intimate us of such change, even if the
        change is “minor” as per your understanding or belief. Any change to
        Your scope of business, without intimating us shall result in breach of
        contract and trust and shall lead to an immediate termination of this
        Agreement.
      </p>
      <p>
        You agree that You will only use your User Account for the purposes of
        using the Offerings and for no other purpose. In particular, you will
        not:
      </p>
      <p>
        Breach the Rules, guidelines, laws, statutes, etc laid down by any
        statutory body in applicable jurisdiction or laid down by FirstPayer
        specifically;<br />
        Resell or sublicense the use of your User Account to any other
        person;<br />
        Use your User Account in a fraudulent or illegal manner, or email or
        send any materials from your User Account which are offensive, unlawful,
        harassing, libellous, defamatory, abusive, threatening, harmful, vulgar,
        obscene or otherwise objectionable;<br />
        Use your User Account to impersonate any person or business in any way
        whatsoever;<br />
        Use your User Account to infringe the copyright, trademark, patent or
        other Intellectual Property Rights of any person;<br />
        Your User Account for sending advertising, chain letters, junk mail,
        “spamming” or any other type of unsolicited email;<br />
        Forge or alter the header or address information contained in any email
        or communication which you send from your User Account or in relation to
        the Services;<br />
        Use your User Account to send or deliver viruses, spyware, malware or
        other harmful, disruptive, or destructive files;<br />
        Use your User Account in such a way which damages FirstPayer or denies
        access to FirstPayer to other users of FirstPayer;<br />
        Intentionally or recklessly use your User Account in a way that degrades
        the performance of FirstPayer for other users;<br />
      </p>
      <h3>Content in the Offering</h3>
      <p>
        You understand that all information (such as data files, written text,
        computer software, or images) which you may have access to as part of,
        or through your use of, the offering are the sole responsibility of the
        person from which such content originated.
      </p>

      <h3>Content on the Platform</h3>
      <p>
        You should be aware that the content presented to you as part of the
        Offering, including but not limited to advertisements and promotional
        material of FirstPayer or other companies, is protected by intellectual
        property rights which are owned by FirstPayer, or the sponsors or
        advertisers who provide that content to FirstPayer (or by other persons
        or companies on their behalf).
      </p>
      <p>
        You shall not modify, rent, lease, loan, sell, distribute, copy or
        create derivative work based on this content (either in whole or in
        part) unless you have been specifically told that you may do so by
        FirstPayer or by the owners of that content, in a separate agreement.
        Any content being disseminated using FirstPayer's sales network or the
        product, service or platform may be pre-screened, reviewed, flagged,
        filtered, modified or simply refused or removed. Any spam or
        pornographic material and / or any illegal content will be immediately
        deleted and we reserve the right to take appropriate legal action.
      </p>
      <p>
        You agree that you are solely responsible for (and that FirstPayer has
        no responsibility to you or to any third party for) any content that you
        create, transmit or display while using the offering or for the
        consequences of your actions (including any loss or damage which
        FirstPayer may suffer) by doing so.
      </p>
      <p>
        You understand that by using the offering you may be exposed to content
        of other users that you may find offensive, indecent or objectionable
        and that, in this respect, you use the offering at your own risk.
        FirstPayer shall not be made responsible for any repugnant content
        circulated on its offering by other users.
      </p>
      <p>
        On noticing any such content, it is your duty to bring it to the
        attention of FirstPayer officials immediately, who will take due care to
        delete it from the offering.
      </p>
      <h3>Specific Terms of Engagement</h3>
      <p>
        FirstPayer will not be responsible for any disputes between the Users
        and their Customers, including issues regarding prices, delivery,
        non-delivery or delay in delivery of Products, quality of Products or
        otherwise. All such disputes will be dealt with by and between the User
        and the Customer Directly, and FirstPayer shall not be a Party to Such
        Disputes.
      </p>
      <p>
        FirstPayer cannot and does not guarantee the timings of any payments
        made or received as such, timing is dependent on the User’s Bank, Card
        Issuing Bank/ Card Processor (Where A Credit/ Debit Card Is Used),
        acquiring financial institution and other service providers.
      </p>

      <p>
        FirstPayer shall not be a Party to the agreement Between the User and
        any Customer in any manner whatsoever. All Agreements are directly
        between Users and their Customers.
      </p>
      <p>
        User shall be responsible for any miscommunication or Incorrect
        User/Third Party/Other information that may be provided to FirstPayer at
        the time of enabling the Services for the User.
      </p>
      <p>
        FirstPayer reserves the Right To Impose limits on the number of
        transactions during any time period, and reserves the right to refuse to
        make payments in respect of transactions exceeding such limit.
        FirstPayer also reserves the Right To Refuse to make payments in respect
        of transactions by Users with a prior history of questionable charges.
      </p>
      <h3>Intellectual Property rights</h3>
      <p>
        You acknowledge and agree that FirstPayer (or FirstPayer's licensors)
        owns all legal right, title and interest in and to the offering,
        including any intellectual property rights which subsist in the Offering
        (whether those rights happen to be registered or not, and wherever in
        the world those rights may exist) and that you are merely a licensee of
        the offering, and not the owner thereof. You further acknowledge that
        the offering may contain information which is designated confidential by
        FirstPayer and that you shall not disclose such information without
        FirstPayer's prior written consent.
      </p>
      <h3>Confidentiality</h3>
      <p>
        The User agrees that they shall not disclose or attempt to use or
        personally benefit from any information that is non-public that the User
        might have come across on the Website or through their Use of the
        Offerings. This obligation shall continue till the time that this
        Non-Public information relating to FirstPayer or its offerings becomes
        publicly known through no action of the User or any associated or
        connected Party.
      </p>

      <p>
        In the event that the Merchant is compelled by an Order of a competent
        Court or a Government or Legal Body (or if they have received a notice
        that such an order is being sought) to divulge such information
        specifically, the User agrees to intimate FirstPayer of such Order or
        notice immediately. The User shall also cooperate with FirstPayer in
        protecting the information to the extent possible as if it were their
        own.
      </p>
      <p>
        FirstPayer may access, preserve and disclosure any of your information
        if required to do so by Law, or ir we believe in good faith that it is
        reasonably necessary to (I) respond to claims asserted against
        FirstPayer or to comply with legal process (II) For fraud prevention,
        risk assessment, investigation, customer support, product development
        and debugging purposes, or (III) Protect the rights, property or safety
        of FirstPayer, its Users or members of the Public.
      </p>
      <h3>Refunds/ Chargebacks</h3>
      <p>
        Refunds may be for:<br />
        Any payment which the User refuses to honour or demands a refund of
        because the products purchased or the delivery thereof, was not as
        promised or was defective, deficient, incomplete and /or unsatisfactory
        for any reason whatsoever, or that payment for the same has already been
        made.<br />
        Any payments by a User using the Offerings, which is returned by the
        acquiring Bank for any reason whatsoever.<br />
        Any transaction that is unsuccessful.<br />
      </p>
      <p>
        In Such Event that the User and the Payer arrive at a satisfactory
        resolution of a problem or a complaint, FirstPayer shall make the refund
        to the Party that the User And the Payee mutually decide and agree upon.
        In such a situation, the User and Payer shall issue an acceptance and
        agreement upon such resolution and shall make no further claims to
        FirstPayer with respect to such transaction or complaint, whatsoever.
      </p>

      <p>
        In such an event, if the Payer and the User are unable to arrive at a
        satisfactory resolution of the problem, FirstPayer shall not make any
        refunds till such dispute is resolved. FirstPayer shall not be held
        liable or responsible for any delays caused due to lack of settlement or
        delay in settlement between the Parties. In the event of the Payee and
        the Payer arriving at a Settlement, FirstPayer shall deal with the said
        monies in accordance with the Terms Of The Settlement arrived at.
      </p>

      <h3>License from FirstPayer</h3>
      <p>
        FirstPayer gives you a Non-Exclusive license to use the software
        provided to you by FirstPayer as part of the offering as provided to you
        by FirstPayer. This license is for the sole purpose of enabling you to
        use and enjoy the benefit of the offering as provided by FirstPayer, in
        the manner permitted by these terms.
      </p>
      <p>
        You are not entitled to commercially exploit, either directly or by
        sale, or transfer commercially or profit from the offering and products.
      </p>
      <p>
        You may not (and you may not permit anyone else to) copy, modify, create
        a derivative work of, reverse engineer, decompile or otherwise attempt
        to extract the source code of the software or any part thereof.
      </p>
      <p>
        Unless FirstPayer has given you specific written permission to do so,
        you may not assign (or grant a sub-license of) your rights to use the
        service or otherwise transfer any part of your rights to use the
        service.
      </p>

      <h3>Privacy Policy</h3>
      <p>
        As a User you understand that your access to the website/Offerings will
        result in collection, Use and storage of the information that you share
        with us, Subject To FirstPayer's Privacy Policy. Through Your access of
        the Website/Offerings, you consent to the collection, use and storage of
        such information, which will be held by FirstPayer and may be processed
        and stored by Banks or Service Providers. For a better understanding and
        explanation of FirstPayer’s practices and policies related to the
        collection, Use, and Storage Of Users’ information, please read
        our&nbsp;Privacy Policy.
      </p>

      <h3>Ending your relationship with FirstPayer</h3>
      <p>
        The Terms will continue to apply until terminated by either you or
        FirstPayer duly, as set out below. FirstPayer may at any time, terminate
        its legal agreement with you if:
      </p>
      <p>
        you have breached any provision of the terms or this document or any
        other applicable conditions (or have acted in manner which clearly shows
        that you do not intend to, or are unable to comply with the provisions
        of the terms); <br />
        FirstPayer is required to do so by law (for example, where the provision
        of the offering to you is, or becomes, unlawful)<br />
        Acted in a suspicious manner;<br />
      </p>
      <p>
        If either FirstPayer itself or by intimation of the Bank or other
        institutes, suspects, that a User has committed a Breach of Terms or any
        other Agreement with FirstPayer or any act of dishonesty or Fraud
        against FirstPayer/ any Bank or other institutes, FirstPayer shall be
        entitled to (A) Suspend all payment under these Terms; (B) Deactivate/
        Suspend your user account and disable your password; And (C) Terminate
        User’s access to the website or the Offerings With Or Without Giving Any
        Notice Of Such Termination To The User; Pending Enquiries By FirstPayer.
        User may cancel his User Account At Any Time By Sending An Email to
        FirstPayer. Please Note That Once Your Account Is Cancelled, FirstPayer
        Does Not Have Any Obligation To Return Any Data Or Information That May
        Reside On Its Servers Or Other Equipment.
      </p>
      <p>
        When these terms come to an end, all of the legal rights, obligations
        and liabilities that you and FirstPayer are subject to in relation to
        the obligation to maintain confidentiality or such other legal rights,
        obligations and liabilities which are expressed to continue
        indefinitely, shall be unaffected by this cessation.
      </p>

      <h3>EXCLUSION OF WARRANTIES</h3>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE OFFERING IS AT
        YOUR SOLE RISK AND THAT THE OFFERING ARE PROVIDED AS IS AND AS
        AVAILABLE.
      </p>
      <p>
        IN PARTICULAR, FirstPayer DOES NOT REPRESENT OR WARRANT TO YOU THAT:<br />
        YOUR USE OF THE OFFERING WILL MEET YOUR REQUIREMENTS,<br />
        YOUR USE OF THE OFFERING WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE
        FROM ERROR, INCLUDING SPECIFICALLY FROM SERVER DOWNTIME<br />
        ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE OFFERING
        WILL BE ACCURATE OR RELIABLE, AND<br />
        ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
        OFFERING IS DONE AT YOUR OWN DISCRETION AND RISK AND FirstPayer WILL NOT
        BE RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR
        LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.<br />
      </p>
      <p>
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        FirstPayer OR THROUGH OR FROM THE OFFERING SHALL CREATE ANY WARRANTY NOT
        EXPRESSLY STATED IN THE TERMS.
      </p>
      <p>
        FirstPayer FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF
        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
        IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      </p>
      <h3>LIMITATION OF LIABILITIES</h3>
      <p>
        SUBJECT TO OVERALL PROVISION IN CLAUSES ABOVE, YOU EXPRESSLY UNDERSTAND
        AND AGREE THAT FirstPayer SHALL NOT BE LIABLE TO YOU FOR:
      </p>
      <p>
        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
        DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER ANY
        THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS
        OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF
        GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF
        PROCUREMENT OF SUBSTITUTE GOODS OR OFFERING, OR OTHER INTANGIBLE LOSS;
      </p>
      <p>
        ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT
        LIMITED TO LOSS OR DAMAGE AS A RESULT OF:
      </p>
      <p>
        ANY CHANGES WHICH FirstPayer MAY MAKE TO THE OFFERING, OR FOR ANY
        PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE OFFERING (OR
        ANY FEATURES WITHIN THE OFFERING);
      </p>
      <p>
        THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND
        OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR
        USE OF THE OFFERING;
      </p>
      <p>
        YOUR FAILURE TO PROVIDE FirstPayer WITH ACCURATE ACCOUNT INFORMATION;
      </p>
      <p>
        YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND
        CONFIDENTIAL;
      </p>
      <p>
        Other content to which references are made, links are provided, etc.<br />
        1.The offering may include hyperlinks to other web sites or content or
        resources. FirstPayer may have no control over any websites or resources
        which are provided by companies or persons other than FirstPayer.<br />
        2.You acknowledge and agree that FirstPayer is not responsible for the
        availability of any such external sites or resources, and does not
        endorse any advertising, products or other materials on or available
        from such web sites or resources.<br />
        3.You acknowledge and agree that FirstPayer is not liable for any loss
        or damage which may be incurred by you as a result of the
        non-availability of those external sites or resources, or as a result of
        any reliance placed by you on the completeness, accuracy or existence of
        any advertising, products or other materials on or available from, such
        websites or resources.<br />
      </p>
      <p>
        Changes to the Terms:<br />
        1.FirstPayer may make changes to the terms from time to time. The same
        will be communicated to you, on the website of FirstPayer by e-mail for
        which you shall have to indicate your approval within 15 days. When
        these changes are made, FirstPayer shall make the amended copy of the
        terms available in an accessible location.<br />
        2.You understand and agree that if you use the offering after the date
        on which the terms have changed, or after the date by which you have to
        respond as per these terms has passed, FirstPayer will treat your use as
        acceptance of the amended terms.<br />
      </p>
      <p>
        General legal terms:<br />
        1.Sometimes when you use the offering, you may (as a result of, or
        through your use of the offering) use a service or download a piece of
        software, or purchase goods, which are provided by another person or
        company. Your use of these other offerings, software or goods may be
        subject to separate terms between you and the company or person
        concerned.<br />
        2.The terms constitute the legal agreement between you and FirstPayer
        and govern your use of the offering, but without prejudice to any
        additional terms which may be part of an agreement specific to the
        service you wish to avail of (but excluding any offering which
        FirstPayer may provide to you under a separate written agreement), and
        completely replace any prior agreements between you and FirstPayer in
        relation to the offering.<br />
        3.You agree that if FirstPayer does not exercise or enforce any legal
        right or remedy which is contained in the terms (or which FirstPayer has
        the benefit of under any applicable law), this will not be taken to be a
        formal waiver of FirstPayer's rights and that those rights or remedies
        will still be available to FirstPayer.<br />
        4.If any court of law, having the jurisdiction to decide on this matter,
        rules that any provision of these terms is invalid, then that provision
        will be removed from the terms without affecting the rest of the terms.
        The remaining provisions of the terms will continue to be valid and
        enforceable.<br />
        5.The terms, and your relationship with FirstPayer under the terms,
        shall be governed by the laws of India, and in particular the laws of
        the State of Haryana, India. You and FirstPayer agree that any dispute
        will be referred to arbitration, with the arbitral panel consisting of
        three arbitrators, one arbitrator being chosen by each party and a third
        being jointly appointed by the two arbitrators so chosen.<br />
      </p>
      <h3>General Terms</h3>
      <p>
        Unless Otherwise Set Out, All Payments May Only Be Made In Indian
        Rupees.
      </p>
      <p>
        FirstPayer May, If Required By Law, Change Any Fee Or Charge Or
        Institute New Fees Or Charges. The User Agrees To Pay All Fees And
        Charges So Levied.
      </p>
      <p>
        FirstPayer Reserves The Right To Vary Any Of The Terms Contained Herein
        By Posting The Revised Terms On FirstPayer’s Website Without Notice To
        The User.
      </p>
      <p>
        The User Hereby Accepts That He May, From Time To Time, Receive From
        FirstPayer Or Its Associates, Communications Such As Service
        Announcements, Advertisements Or Administrative Communications.
      </p>
      <p>
        FirstPayer Shall Not Be Liable For Any Direct, Indirect, Punitive,
        Incidental, Exemplary, Special Or Consequential Damages Or Any Loss Of
        Profit, Business, Revenue, Goodwill Or Anticipated Savings Or Any
        Damages Whatsoever Arising Out Of Or In Any Way Connected With The Use
        Of The Website By User Or Otherwise
      </p>
      <p>
        FirstPayer Or Service Provider Shall In No Event Be Liable To A User Or
        Any Third Party For Any Loss Or Damage Whatsoever Or Howsoever Caused
        Arising Directly Or Indirectly In Connection With The Website And
        Services, Including Without Limitation Any:<br />
        1.Loss Of Data;<br />
        2.Interruption Or Stoppage To The User’s Access To And/Or Use Of The
        Website And Services;<br />
        3.Any Consequential Loss Or Damage Or Loss Of Profit, Business, Revenue,
        Goodwill Or Anticipated Savings, Arising Out Of The Performance Of The
        Services Or Otherwise.<br />
      </p>
      <p>
        FirstPayer Makes No Representation Or Warranty, Whether Express Or
        Implied, Written Or Oral, About The Quality, Suitability, Reliability,
        Availability, Merchantability, Fitness For A Purpose, Delivery, Lack Of
        Viruses Or Other Harmful Components Or Accuracy Of Information Relating
        To The Products Sought To Be Purchased By The User. Each User
        Acknowledges That FirstPayer's Or A Service Provider’s Services May Not
        Be Uninterrupted Or Error Free. A User Also Acknowledges That The
        Services Provided By Any Service Provider To FirstPayer, Which Is Passed
        On To The User Under These Terms, Can Be Brought To An Abrupt End In Any
        Event Whatsoever By Any Of The Service Providers For Any Reason
        Whatsoever.
      </p>
      <p>
        FirstPayer’s Sole Obligation And User’s Sole And Exclusive Remedy In The
        Event Of Interruption To The Payment Services Or Loss Of Use And/Or
        Access To The Payment Services, Shall Be To Use All Reasonable Endeavors
        To Restore The Payment Services As Soon As Reasonably Possible.
      </p>
      <p>
        In No Event Shall FirstPayer Or A Service Provider Be Liable To A User
        Or Any Other Third Party For Any Applicable Taxes And Government Levies.
      </p>
      <p>
        In No Event Will FirstPayer’s Total Liability To User For All
        Liabilities, Losses, And Causes Of Action Arising Out Of Or Relating To
        These Terms And Conditions; Or The User’s Use Of The Website; However
        Caused, Exceed The Amount Paid By User, If Any, For Utilising The
        Website Or One Thousand Rupees (Rs.1000), Whichever Is Lesser.
      </p>
      <p>
        Any Link On The FirstPayer Website To A Third Party Site Is Not An
        Endorsement Of That Website. The Use Or Browsing By A User Of Any Other
        Website Shall Be Subject To The Terms And Conditions In Each Such
        Website.
      </p>
      <p>
        FirstPayer Does Not Endorse Any Advertiser Or Merchant Linked To Its
        Website. The User Must Verify All Information Provided By The Merchants/
        Advertisers Before Arriving At His Decision To Purchase Any Product. The
        User’s Participation In Any Event/ Contest/ Promotion Shall Be Governed
        By The Terms And Conditions Mentioned In The Respective Event/ Contest/
        Promotion Along With These Terms.
      </p>
      <p>
        FirstPayer Shall Have The Right, At Its Sole Discretion, To:<br />
        restrict or terminate a User’s access to its Services;<br />
        modify or discontinue its Services or any part thereof;<br />
        require a User to provide details in respect of any transactions and any
        other details as required by FirstPayer from time to time; without
        incurring any liability therefor.<br />
      </p>
      <p>
        FirstPayer Shall Have The Right, In Its Sole Discretion, For Any Or No
        Reason, And Without Penalty, To Suspend Or Terminate A User’s Use Of The
        Website And Its Services Or Any Part Thereof, With Or Without Notice.
      </p>
      <p>
        FirstPayer Shall Not Be Liable For Any Breach Of These Terms Due To Any
        Force-Majeure Event Such As Act Of God, Governmental Policy/Authority,
        Fire, Lightning, Explosion, Flood, Inclement Weather Conditions, Power
        Failures, Failure In Any Communication Systems, Equipment Breakdown,
        Strikes, Lock-Out, Earthquakes, Riots, War (Declared And Undeclared),
        Rebellion, Sabotage, Computer Hacking, Unauthorized Access To Computer
        Data And Storage Devices, Computer Crashes Or Any Other Cause Beyond The
        Control Of FirstPayer. If Any Part Of These Terms Is Determined To Be
        Invalid Or Unenforceable Pursuant T o Applicable Law Including, But Not
        Limited To, The Warranty Disclaimers And Liability Limitations Set Forth
        Herein, Then The Invalid Or Unenforceable Provision Will Be Deemed
        Superseded By A Valid, Enforceable Provision That Most Closely Matches
        The Intent Of The Original Provision And The Remainder Of The Terms
        Shall Continue In Effect. FirstPayer May Assign Or Transfer Its Rights
        And Obligations To Any Other Party. Incase Of Any Dispute Between The
        Parties On The Interpretation Or Implementation Of These Terms, The
        Appropriate Court In&nbsp;Gurugram&nbsp;Shall Have Exclusive
        Jurisdiction.
      </p>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Tc",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {
    $(".content-box").animate({ scrollTop: 0 }, 500);
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../static/css/tc.css";
</style>
