<template>
  <div class="price-box">
    <div class="container">
      <div class="group1">
        <ul>
          <li class="g-left">
            <p>
              Home
              <span class="arrow">></span>
              Pricing
            </p>
            <h2 class="sub-title">Pricing</h2>
          </li>
          <li class="g-right">
            <h2 class="third-title">You only need to pay for each transaction,no installation/setup fees
              and maintenance fees</h2>

              <router-link to="/ContactUs/4"><button class="start-btn">Start immediately</button></router-link>
          </li>
        </ul>
      </div>
      <div class="group2">
        <div class="gp2-left">
          <p class="text1">PRICING AND FEES</p>
          <h2 class="text2">Transparent pricing and 0 setup and installation fees</h2>
          <p class="text3">Allows you to open global localized acquiring at the lowest cost, 
            reducing the cost and risk of overseas business expansion</p>
          <ul class="fee-lists">
            <li>Free account</li>
            <li>Easy integration</li>
            <li>No installation fee</li>
            <li>Smart dashboard</li>
            <li>Custom price</li>
            <li>24/7 support</li>
          </ul>
        </div>
        <div class="gp2-right">
          <img src="../../static/img/img1.png"
               alt="">
        </div>
      </div>
      <div class="group3">
        <h2>Start exploring FirstPayer global localized payment now</h2>
        <p>100+ payment methods world wide, industry-leading success rate,and excellent
          checkout experience</p>
        <router-link to="/ContactUs/4"><button class="start-btn2 start-btn">Start immediately</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    msg: String,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../static/css/Price.css';
</style>
