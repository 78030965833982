<template>
  <div class="wrapper">
    <div class="main-content">
      <div class="list-item">
        <img src="../../static/img/FAQ.png"
             alt="">
      </div>
      <el-collapse v-model="activeNames"
                   @change="handleChange">
        <el-collapse-item title="How long does it take to open an account and product review?" name="1">
          <div>Both account application and product review will be completed within 1 working day.</div>
        </el-collapse-item>
        <el-collapse-item title="How to connect after opening an account?" name="2">
          <div>FirstPayer
            business consultants will assist you with establish a discussion group and provide unified API technical
            documentation guidance to cooperate in docking, testing, and problem solving during use.</div>
        </el-collapse-item>
        <el-collapse-item title="What types of products are not supported by FirstPayer?" name="3">
          <div>Military, weapons and mercenary services;</div>
          <div>pornography, drugs and related equipment;</div>
          <div>gambling and lottery business;</div>
          <div>fakes, infringing products;</div>
          <div>prescription drugs and services;</div>
          <div>unauthorized products, pirated CDs;</div>
          <div>tobacco and alcoholic beverages;</div>
          <div>virtual currency transactions, Such as Bitcoin transactions;</div>
          <div>other local laws expressly prohibit business;</div>
          <div>please consult a business adviser for more details.</div>
        </el-collapse-item>
        <el-collapse-item title="What's your pricing?" name="4">
          <div>There is no setup fee, monthly fee or any other hidden fees. We only charge a transaction fee when you are paid. 
          Regarding the pricing for different local payment methods, please contact our sales for details.</div>
        </el-collapse-item>

        <el-collapse-item title="Why is my settlement delayed?" name="5">
          <div>The settlement may be put on hold for a specific amount of time in the event of any termination or we find
            that you have stopped processing payments. This is to cover any possible disputes or chargebacks the users may file.</div>
        </el-collapse-item>
        <el-collapse-item title="Why should I give my KYC documents to FirstPayer?" name="6">
          <div>KYC is an acronym for "Know your Customer", a term used for customer identification process. It involves making reasonable efforts to
            determine true identity and beneficial ownership of accounts, source of funds and the nature of your business.</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  props: {
    msg: String,
  },
  data() {
    return {
      activeNames: [''],
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../static/css/faq.css';
</style>
