<template>
  <div class="privacy-box">
    <div class="text1">
      <h1>Privacy Policy</h1>
      <p>
        Funshine Tech Private Limited ("us", "we", or "our") operates the <a>https://www.firstpayer.com</a> 
        website (the "Service" or “FirstPayer”). This page informs you of our policies regarding the collection, 
        use and disclosure of Personal Information when you use our Service. We will not use or share your information 
        with anyone except as described in this Privacy Policy.
      </p>
      <p>
        This Privacy Policy ("Policy") applies to those who visit our website FirstPayer("Service"). If you have questions or complaints regarding our privacy policy or practices, please contact us at support@FirstPayer.com. While providing our Services, we may enter into agreements with our Partners located within the Indian territory, and provide them access to our Service, which includes the processing of information relating to their customers. Information shared in our Website may be shared with our Partners, in order to provide our customers with the Services.
      </p>

      <p>
        We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. You shall avail our services only in the event that you agree with both our Terms of Service and Privacy Policy. In the event that you are using our Services, we deem your acceptance of both terms and any other terms and conditions and policies, applicable herein.
      </p>

      <p>
        We only access such information as reasonably necessary to provide the Service, as otherwise authorized or as required by law. We are the data controllers of the information. We work with our Partners to help them process payment transactions. As the processors of personal information on behalf of our Merchant, we follow their instructions with respect to the information they control to the extent consistent with the functionality of our Service.
      </p>

      <p>
        In doing so, we implement technical, physical and administrative measures against unauthorized processing of such information and against loss, destruction of, or damage to, personal information. While we are required to comply with the instructions of our Merchant, we are still committed to protecting the confidential information of our customers, and have adopted this Policy to protect Personal Information.
      </p>
      <p>
        This Policy applies to all transactions involving FirstPayer , and are
        in addition to, and not in derogation of, the applicable Terms and
        Conditions governing our Services. This Policy is incorporated in our
        terms of use and therefore governs your use of our Services. By visiting
        the Website and/or utilizing any Services offered by FirstPayer , you
        agree to and accept the our terms of use governing your use of any
        Services offered by FirstPayer . As a user of the Services, your access
        to or use of the Website and Services offered by FirstPayer is
        conditional upon your acceptance of and continued compliance with this
        Policy. By using the Website and/or the Services, you agree to be bound
        by this Policy and all rules, guidelines and/or policies of FirstPayer ,
        as may be modified by FirstPayer from time to time, unconditionally and
        at all times, and further agree and confirm that you consent to
        providing FirstPayer with your personal information for the purpose set
        out in this Policy and you understand that such collection, storage and
        handling is lawful, necessary and permissible under the applicable laws.
      </p>

      <h3>What Information Do we collect and use?</h3>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you.
      </p>
      <p>
        Personally identifiable information may include, but is not limited to:<br>
        1.Name of user<br>
        2.Address<br>
        3.Email ID<br>
        4.Contact number<br>
        5.Know your customer (" KYC") documents as per RBI regulations and any
          other information as may be applicable ("Personal Information")<br>
      </p>
      <p>Sensitive information may include, but is not limited to:<br>
          Financial information including inter alia credit card, debit card
          details, bank account
      </p>
      
      <h3>How Do We Collect, Use and Share Your Information</h3>
      <p>
        We collect the email addresses of those who communicate with us via
        email, information about the pages that the user accesses on the
        Website, and information voluntarily submitted by the user of the
        website. The information we collect is used to provide the Service to
        you, to improve the quality of the Website and Service, to communicate
        information about the Service.
      </p>

      <p>
        Your information will never be sold to or traded or shared with other
        companies or organizations for commercial purposes or otherwise, unless
        we have been expressly authorized by you, either in writing or in
        electronic form to do so or in a manner as outlined in this Policy.
      </p>

      <p>
        While your Personal Information is collected primarily from you, we may
        also obtain information about you from other sources in order to verify
        the information submitted by you. This verification is primarily done in
        order to address various security concerns, and to ensure that you
        comply with the provisions of applicable laws, our terms and conditions
        of Services available, and other terms and conditions as may be
        prescribed by us from time to time. FirstPayer may transfer personal
        information to companies that help us provide our Service such as
        third-party payment processors etc. Transfers to these third parties are
        covered by the provisions in this Policy, and the service agreements
        with such third parties. When you access or use our Website or the
        Service, or otherwise provide information to us, you are consenting, on
        behalf of yourself and your authorized representatives or end-users, to
        the processing and transfer of information in and to India which may
        have different privacy laws from your country of residence. We will take
        all steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Policy.
      </p>

      <h3>How do we deal with the Data we collect?</h3>
      <p>
        FirstPayer shall use your Data for: 
        <ul>
          <li>
          Sending updates, news and newsletters to our users, in the event that you willingly 
          subscribe to the newsletter during signup, or anytime after signup;
          </li>
          <li>
          Verification with respect to or in cases of chargebacks;
          </li>
          <li>
          On billing payment services or any other such use that FirstPayer deems fit or necessary 
          for providing you with relevant services;
          </li>
        </ul> 
      </p>

      <h3>
        How do we store this Data?
      </h3>
      <p>
        All data stored is first encrypted and then stored securely on our servers. Only authorized personnel 
        can access the stored Data. Your information is otherwise securely archived in our database after 
        processing and usage. FirstPayer use industry standard measures to protect the Personal Information 
        that is stored in our database.
      </p>
      <p>
        FirstPayer limits the access to user's Personal Information to those employees and contractors 
        who need access to perform their job function, such as our customer service personnel.
      </p>

      <h3>
        Log Data
      </h3>
      <p>
        We record information relating to your use of FirstPayer , such as the searches you undertake, 
        the pages you view, your browser type, IP address, location, requested URL, referring URL, 
        and timestamp information. We use this type of information to administer FirstPayer and provide 
        the highest possible level of security and service to you. We also use this information in the 
        aggregate to perform statistical analyses of User behavior and characteristics in order to measure 
        interest in and use of the various areas of FirstPayer . However, you cannot be identified from this 
        aggregate information.
      </p>
      <p>
        In addition, we may use third party services such as Google Analytics that collect, monitor and analyze 
        this type of information in order to increase our Service's functionality. These third party service providers 
        have their own privacy policies addressing how they use such information.
      </p>

      <h3>
       Cookies
      </h3>
      <p>
        Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
      </p>

      <h3>
        Marketing Communication
      </h3>
      <p>
        FirstPayer may, from time to time, send you an email regarding the FirstPayer service or any other services, which we believe could be of interest to you. Only FirstPayer will send you these direct mails and only if you indicate that you do not object to these offers. You will always have the option of opting out of receiving such mail.
      </p>

      <h3>
        Service Providers
      </h3>
      <p>
        We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. Communications
      </p>
      <p>
        We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.
      </p>

      <h3>
        Security
      </h3>
      <p>
        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
      </p>

      <h3>
        Transfer of Data
      </h3>

      <p>
        Your information, including Personal Information, may be transferred to and maintained on computers or systems located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. If you are located outside India and choose to provide information to us, please note that we transfer the information, including Personal Information, to India and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      </p>

      <p>
        FirstPayer does not share user's Personal Information with any third party apart from financial institutions such as banks, RBI or other regulatory agencies (as may be required) and to provide you with services that FirstPayer offers through Onion pay, conduct quality assurance testing, facilitate creation of accounts, provide technical and customer support, or provide specific services, such as synchronization of user's contacts with other software applications, in accordance with user's instructions. These third parties are required not to use user's Personal Information other than to provide the services requested by you.
      </p>

      <p>
        FirstPayer may share user's Personal Information with our parent company, subsidiaries, joint ventures, or other companies under a common control (collectively, the " AFFILIATES") that FirstPayer may have now or in the future, in which case FirstPayer Will require them to honor this Privacy Policy. If another company acquires our company or our assets, that company will possess user's Personal Information, and will assume the rights and obligations with respect to that information as described in this Privacy Policy. FirstPayer may disclose user's Personal Information to third parties in a good faith belief that such disclosure is reasonably necessary to (a) take action regarding suspected illegal activities; (b) enforce or apply our terms and conditions and Privacy Policy; (c) comply with legal process, such as a search warrant, subpoena, statute, or court order; or (d) protect our rights, reputation, and property, or that of our Users, Affiliates, or the public.
      </p>

      <p>
        Please note that FirstPayer are not required to question or contest the validity of any search warrant or other similar governmental request that FirstPayer receives.
      </p>

      <p>
        FirstPayer may disclose information in the aggregate to third parties relating to User behavior in connection with actual or prospective business relationships with those third parties, such as advertisers and content distributors. For example, FirstPayer may disclose the number of Users that have been exposed to, or clicked on, advertising banners.
      </p>

      <h3>3rd Party links</h3>
      <p>
        Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </p>

      <h3>
        Children's Privacy
      </h3>
      <p style="font-weight: bold">
        Our Service does not address anyone under the age of 13 ("Children").
      </p>
      <p>
          We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your Child has provided us with Personal Information, please contact us immediately. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.
      </p>

      <h3>
        Legal Disclosure
      </h3>

      <p>
        We reserve the right to disclose your personal data as required by applicable law and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or other legal process served on us. Your personal data will also be shared between the FirstPayer services for the activities permitted under this Policy. In the event FirstPayer goes through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, including an acquisition which occurs as a result of our bankruptcy, the Merchant user account and data will likely be among the assets transferred. 
      </p>

      <h3>
        Changes To This Privacy Policy
      </h3>
      <p>
        FirstPayer reserves the right to change, modify, add, or remove portions of this privacy policy at any time for any reason. Such changes shall be effective immediately upon posting. We suggest that you review this policy periodically for changes. The current version of this Policy can be accessed from the link on our homepage, or at the bottom of our other Website pages. You acknowledge that by accessing our Website after we have posted changes to this Policy, you are agreeing to the terms of the privacy policy as modified.
      </p>
      <p>
        By using FirstPayer and/or by providing your information, you consent to the collection and use of the information you disclose on FirstPayer in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.
      </p>

      <h3>
        Governing Law
      </h3>
      <p>
        This is an Indian website and is subject to the laws of India. Please note, laws related to personally identifiable information vary from country to country.
      </p>

      <h3>
        No Unlawful or Prohibited Use
      </h3>

      <p>
        As a condition of your use of this Website, you agree that you will not use this Website or the information or software contained herein for any purpose that is unlawful or prohibited by these terms and conditions.
      </p>

      <h3>
        Disclaimer
      </h3>
      <p>
        While every effort is made to ensure the accuracy of information offered on these pages, we shall have no liability for errors, omissions or inadequacies in the information contained herein or for interpretations thereof. This Website could include inaccuracies or out-dated information and is subject to change at any time without notice. Your use of this Website is at your own risk. You agree to hold FirstPayer harmless from and not sue for any claims based upon using this Website.
      </p>

      <p>
        THE INFORMATION, DOCUMENTATION, SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON THE FIRSTPAYER WEBSITE ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY OF ANY KIND. FIRSTPAYER AND ITS AGENTS DISCLAIM ALL WARRANTIES RELATING TO THE INFORMATION, DOCUMENTATION, SOFTWARE AND SERVICES CONTAINED ON THE FIRSTPAYER WEBSITE, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES AGAINST INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, TO THE EXTENT INAPPLICABLE. FIRSTPAYER SHALL NOT BE LIABLE FOR DAMAGES, WHETHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL, AS A RESULT OF THE REPRODUCTION, MODIFICATION, DISTRIBUTION OR USE OF THE INFORMATION, DOCUMENTATION, SOFTWARE AND SERVICES.
      </p>
      <p class="text"></p>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
export default {
  name: "Privacy",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
   mounted() {
    $('.content-box').animate({ scrollTop: 0 }, 500)
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../static/css/tc.css";
</style>
