<template>
  <div class="wrapper">
    <div class="main-content">
      <div class="list-item">
        <img src="../../static/img/aboutus-icon1.png"
             alt="">
      </div>
      <div class="about_text_1">
        <p>FirstPayer is an online payment service provider. By integrating a variety of local
          payment methods on
          FirstPayer platform, we provide a one-stop global payment solution which allows people can pay with
          their
          preferred local payment methods without a hassle.</p>
        <p>FirstPayer was founded in January 2020.
          At
          FirstPayer , we are passionate about making global payments easier. We built FirstPayer to take the
          hassle out
          of collecting payments globally, so our merchants could focus on their business.</p>
        <p>When you
          run a
          global business, you may want to integrate credit cards to collect payments, however, you will find
          most
          countries have their own popular local payment methods when you look at each specific country. With
          FirstPayer
          one-stop global payment solution, the merchants simply sign up at firstpayer.com and integrate all
          these payment
          methods through one API.</p>
        <h3 class="about_title-text">OUR VISION</h3>
        <p>
          To establish the best-in-class integrated payments business with continuous and
          progressive innovation to
          create values for our customers and partners globally.</p>
        <h3 class="about_title-text">OUR MISSION</h3>
        <p>
          To provide diversified innovative and cost effective payment solutions to our
          customers and partners
          worldwide.<br />To provide robust and global platform to enable fast and easy processing for
          acquirers.<br />Team
          of professional intellects to manage and deliver the needs of our global customers.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  props: {
    msg: String,
  },
  data() {
    return {}
  },
  methods: {
      
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import '../../static/css/faq.css'; */
@import '../../static/css/aboutUs.css';
</style>
