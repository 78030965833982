import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import FAQ from '../components/FAQ'
import Price from '../components/Price'
import TC from '../components/Tc'
import Privacy from '../components/Privacy'



const routes = [
    { path: '/', redirect: '/Home/1'},
    { path: '/Home/:id', component: Home,name:"Home"},
    { path: '/AboutUs/:id', component: AboutUs,name:"AboutUs"},
    { path: '/ContactUs/:id', component: ContactUs,name:"ContactUs"},
    { path: '/FAQ/:id', component: FAQ,name:"FAQ"},
    { path: '/Price/:id', component: Price,name:"Price"},
    { path: '/TC', component: TC,name:"TC"},
    { path: '/Privacy', component: Privacy,name:"Privacy"},
];
const router = new Router({
    mode: 'history',
    routes,
})
// router.beforeEach((to, from, next) => {
//     if(document.querySelector('.content-box')){
//         document.querySelector('.content-box').scrollTop = 0;
//         next()
//     }
// });
Vue.use(Router)
export default router
