import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui' 
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import axios from 'axios';
import VueAxios from 'vue-axios'
import $ from "jquery"

import  remAndPhoneConfig from './config/remConfig'
remAndPhoneConfig();

Vue.use(VueMeta)
Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(VueAxios,axios)
window.$ = $
new Vue({
  router,
  render: h => h(App),
  
}).$mount('#app')
