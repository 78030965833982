<template>
  <div class="home-box">
    <div class="block">
      <el-carousel :interval="4000">
        <img src="../../static/img/back1.png" class="fake-img" alt="">
        <el-carousel-item style="height:auto !important;" v-for="item in backList"
                          :key="item.id">
          
          <img style="display:block;width: 100%; height: 100%"
               :src="item.url"
               alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="group1-item">
      <h2 class="h_text">FirstPayer is committed to providing professional online payment solutions in India and LATAM</h2>
      <ul class="group1-item-list">
        <li v-for="item in cardItemLists"
            :key="item.index"
           >
          <div id="cardItem" class="card-item"
               v-on:mouseover="addActive($event)"
               v-on:mouseout="removeActive($event)">
            <div class="img-box">
              <img class="hide1"
                   :src="item.icon"
                   alt="">
              <img class="hide2"
                   :src="item.icon1"
                   alt="">
            </div>
            <h2>{{item.h2Text}}</h2>
            <p>{{item.pText}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="group1-item">
      <h2 class="h_text2">FirstPayer Designs industry solutions that are tailor-made for YOU</h2>
      
      <ul class="group2-item-list">
        <li v-on:mouseover="addRotate($event)"
            v-on:mouseout="removeRotate($event)"
            v-for="item in itemLists"
            :key="item.idx">
          <img :src="item.url"
               alt="">
          <p class="p_text">{{item.name}}</p>
        </li>
      </ul>
    </div>
    <div class="group3-item">
      <ul class="group3-item-list">
        <li><img src="../../static/img/img3.png"
               alt=""></li>
        <li>
          <h2 class="group3-text">API Integration - Completed within 1 day</h2>

          <p class="group3-text2">
            After API integration, payments are made automatically via your app or
            backend system to reduce your
            operating costs
          </p>
        </li>
      </ul>
    </div>
    <div class="group4-item">
      <img src="../../static/img/dis.png"
           alt="">
    </div>
    <div class="group6-item">
      <h2 class="h_text3">FirstPayer is committed to providing professional online payment solutions in India and LATAM</h2>
      <ul class="group6-item-list">
        <li v-for="item in countryLists"
            :key="item.idx">
          <img :src="item.url"
               alt="">
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
  },
  data() {
    return {
      cardHeight: 100,
      backList: [
        {
          id: 1,
          url: require('../../static/img/back1.png'),
        },
        {
          id: 2,
          url: require('../../static/img/back1.png'),
        },
        {
          id: 3,
          url: require('../../static/img/back1.png'),
        },
        {
          id: 4,
          url: require('../../static/img/back1.png'),
        },
      ],
      cardItemLists: [
        {
          index: 0,
          icon1: require('../../static/img/icon1.png'),
          icon: require('../../static/img/icon1-select.png'),
          h2Text: 'Best Success Rate',
          pText: 'Payment success rate is higher than 99.9%',
        },
        {
          index: 1,
          icon1: require('../../static/img/icon2.png'),
          icon: require('../../static/img/icon2-select.png'),
          h2Text: 'Most Stable',
          pText: 'Stable operation for more than 1 year',
        },
        {
          index: 2,
          icon1: require('../../static/img/icon3.png'),
          icon: require('../../static/img/icon3-select.png'),
          h2Text: 'Best User Experience',
          pText: 'Continuously optimize the cash register conversion rate',
        },
        {
          index: 3,
          icon1: require('../../static/img/icon4.png'),
          icon: require('../../static/img/icon4-select.png'),
          h2Text: 'Top Notch Security',
          pText: 'Multiple encryption methods to protect data security',
        },
        {
          index: 4,
          icon1: require('../../static/img/icon5.png'),
          icon: require('../../static/img/icon5-select.png'),
          h2Text: 'Fastest Checkout',
          pText: 'Fastest D+0 T+0 settlement',
        },
        {
          index: 5,
          icon1: require('../../static/img/icon6.png'),
          icon: require('../../static/img/icon6-select.png'),
          h2Text: 'Support All Devices',
          pText: 'Websites,iOS,Android devices',
        },
      ],
      itemLists: [
        {
          idx: 0,
          name: 'Social media streaming',
          url: require('../../static/img/c1.png'),
        },
        {
          idx: 1,
          name: 'Cross-boarder E-commerce',
          url: require('../../static/img/c2.png'),
        },
        {
          idx: 2,
          name: 'Game publishing',
          url: require('../../static/img/c3.png'),
        },
        {
          idx: 3,
          name: 'Online reading',
          url: require('../../static/img/c4.png'),
        },
      ],
      countryLists: [
        {
          idx: 0,
          url: require('../../static/img/country1.png'),
        },
        {
          idx: 1,
          url: require('../../static/img/country2.png'),
        },
        {
          idx: 2,
          url: require('../../static/img/country3.png'),
        },
        {
          idx: 3,
          url: require('../../static/img/country4.png'),
        },
        {
          idx: 4,
          url: require('../../static/img/country5.png'),
        },
      ],
    }
  },

  computed: {
    //  scrollerHeight: function() {
    //   return (300) + 'px';
    // }
  },
  methods: {
    addActive($event) {
      //   console.log($event.currentTarget)
      //  console.log(2,$event.target);
      $event.currentTarget.className = 'card-item active'
    },
    removeActive($event) {
      $event.currentTarget.className = 'card-item'
    },
    addRotate($event) {
      $event.currentTarget.className = 'active'
    },
    removeRotate($event) {
      $event.currentTarget.className = ''
    },
  },
//   mounted(){
//     let cardWidth = document.getElementsByClassName("card-item")[0].offsetWidth + 'px'
//     this.cardHeight = cardWidth
//     console.log(cardWidth)
// },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../static/css/home.css';
</style>
