<template>
  <div id="app" class="wrapper-box">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo nav"
      mode="horizontal"
      background-color="#263042"
      text-color="#fff"
      active-text-color="#263042"
    >
      <!-- <el-menu-item style="margin-right:15px;"
                    index="0">
        <div class="header-logo">
          <img src="../static/img/logo.png"
               alt="" />
        </div>
      </el-menu-item> -->
      <div class="header-logo">
        <img src="../static/img/logo.png" alt="" />
      </div>
      <el-menu-item index="1">
        <router-link to="/Home/1">Home</router-link>
      </el-menu-item>
      <el-menu-item index="2">
        <router-link to="/AboutUs/2">About Us</router-link>
      </el-menu-item>
      <el-menu-item index="3">
        <router-link to="/Price/3">Price</router-link>
      </el-menu-item>
      <el-menu-item index="4">
        <router-link to="/ContactUs/4">Contact Us</router-link>
      </el-menu-item>
      <el-menu-item index="5">
        <router-link to="/FAQ/5">FAQ</router-link>
      </el-menu-item>
    </el-menu>

    <div class="content-box">
      <router-view></router-view>
      <footer class="group4">
        <div class="content4">
          <ul class="text_5" style="width: 14%">
            <li>
              <img src="../static/img/logo.png" alt="" />
            </li>
          </ul>
          <ul class="text_5 underline" style="width: 12%">
            <li data-url="privacy.html">
              <router-link to="/Privacy">Privacy Policy</router-link>
            </li>
            <li data-url="tc.html">
              <router-link to="/TC">T&amp;C</router-link>
            </li>
            <li data-url="" @click="toContact">
              <router-link to="/ContactUs/4">Contact Us</router-link>
            </li>
            <!-- to="/ContactUs" -->
          </ul>
          <ul class="text_5" style="width: 40%; margin-right: 30px">
            <li>
              <span class="title">Company:</span>
              <p>Funshine Tech Private Limited</p>
            </li>
            <li>
              <span class="title">Address:</span>
              <p>
                B1/H3 Mathura Road, Mohan Co-Operative Indl Area, Delhi, South
                Delhi, Delhi, India , 110044
              </p>
            </li>
            <li>
              <span class="title">Email:</span>
              <p>funshinetech@gmail.com</p>
            </li>
          </ul>
          <ul class="text_5" style="width: 22%">
            <li>
              FirstPayer is committed to providing professional online payment
              solutions in India and LATAM. We provide you with a safe and
              convenient one-stop payment service based on our rich experience
              in emerging markets.
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// import $ from "jquery"
export default {
  name: "app",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    toContact() {
      // this.$router.push({name:'ContactUs',path:'/ContactUs'})
    },
  },
  mounted() {
    this.activeIndex = this.$route.params.id;
  },
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no",
      },
    ],
  },
  watch: {
    $route(to) {
      // to-->the componet which will to，from is the component where from， they are objects, u can print, and they have param property
      if (to.params.id) {
        this.activeIndex = to.params.id;
      } else {
        this.activeIndex = " ";
      }
    },
  },
};
</script>
<style>
@import "../static/css/base.css";
@import "../static/css/public.css";
</style>
